import React from 'react';
import HomePageBanner from '../../data/images/page-header-images/HomePageBanner.jpg';
import { WelcomeCard } from './welcome-card';
import { OurAccreditationsCard } from './our-accreditations-card';
import { LocationAndContactCard } from './location-and-contact-card';

interface HomePageProps {
  className?: string;
}

export const HomePage = ({ className }: HomePageProps) => {
  return (
    <div className={className}>
      <img
        src={HomePageBanner}
        alt="Home Page Banner"
        className="w-full pb-5"
      />
      <h2 className="italic pb-3">
        Breeders of Multigenerational Australian Labradoodles
      </h2>
      <div className="flex flex-row space-evenly">
        <WelcomeCard className="w-1/3" />
        <OurAccreditationsCard className="w-1/3" />
        <LocationAndContactCard className="w-1/3" />
      </div>
    </div>
  );
};
