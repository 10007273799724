export interface DogData {
  imgName: string;
  dogName: string;
  sire: string;
  dam: string;
  alaaNum: string;
  alcaNum: string;
  dogInfo: string;
  tableData: DogTableData;
  pawPrintLinkUrl?: string;
}

export type DogTableData = string[][];

const lizzyData: DogTableData = [
  ['Weight', '51 lbs'],
  ['Height', '22 in'],
  ['Color', 'Chocolate (bbEE)'],
  ['OFA Hips', 'Good'],
  ['OFA Elbows', 'Normal'],
  ['Thyroid', 'Normal'],
  ['OFA Eye', 'Normal'],
  ['EIC', 'Clear'],
  ['PRA', 'Clear by Parentage'],
  ['vWD', 'Clear by Parentage'],
  ['IC', 'Clear'],
];

const lizzyInfo: string =
  'Lizzy is a chocolate standard size Australian Labradoodle with a beautiful wavy fleece coat.  She comes from some great service and therapy bloodlines which include Tegan Park Cool Dude.  She has nice conformation with a nice saber tail.  She is so smart!  She learns commands easily and gives good eye contact.  She is a happy girl who loves to play fetch.  She loves being with her family and most of all a good belly rub in the recliner with Dad.  Her spunky love of life is contagious.  She brings much joy to our lives!';

const honeyPieInfo: string =
  "Honey Pie is a large medium size Australian Labradoodle with a gorgeous wavy fleece coat.  Born chocolate, her coat is lightening to a frosted cafe color.  We couldn't be more thrilled with her excellent health testing and are looking forward to seeing what she will produce. Sweet and loyal, this girl loves being petted and rubbed. She will ever so gently ask you to keep petting by offering you her paw.  She loves the outdoors and to get the other dogs in a good catch me if you can game which she always wins.";

const sadieInfo: string =
  'Sadie is our gorgeous black beauty.  She is a large medium Australian Labradoodle with a super soft wavy fleece coat that is a true black with no fade. She is a happy soul which can be seen by her constantly wagging tail. She is smart and comical with a wonderful cooperative temperament.  Sadie loves toys and usually manages to trick the other dogs out of them any time she wishes.';

const honeyPieData: DogTableData = [
  ['Weight', '45 lbs'],
  ['Height', '20 in'],
  ['Color', 'Chocolate (bbEe)'],
  ['PennHIP DI', '0.28/0.27'],
  ['OFA Hips', 'Good'],
  ['OFA Elbows', 'Normal'],
  ['Thyroid', 'Normal'],
  ['OFA Eye', 'Normal'],
  ['DM, EIC, HNPK & NEWS', 'Clear'],
  ['PRA-PRCD', 'Clear'],
  ['PRA-crd4/cord1', 'Clear'],
  ['vWD', 'Clear'],
  ['IC', 'Clear'],
];

const sadieData: DogTableData = [
  ['Weight', '45 lbs'],
  ['Height', '20 in'],
  ['Color', 'Black (BbEe)'],
  ['PennHIP DI', '0.41'],
  ['OFA Hips', 'Good (final)'],
  ['OFA Elbows', 'Normal'],
  ['Thyroid', 'Normal'],
  ['OFA Eye', 'Normal'],
  ['DM, EIC, HNPK & NEWS', 'Clear'],
  ['PRA-PRCD', 'Clear'],
  ['PRA-crd4/cord1', 'Clear'],
  ['vWD', 'Clear'],
  ['IC', 'Clear'],
];

export const ourDogsData: DogData[] = [
  {
    imgName: 'lizzy',
    dogName: 'Berkshire Hills Miss Lizzy',
    sire: 'Snug Harbor Kiefer',
    dam: 'Berkshire Hills Millicent',
    alaaNum: 'ALAA-041083',
    alcaNum: 'C023-11172013-033-LB1',
    dogInfo: lizzyInfo,
    tableData: lizzyData,
  },
  {
    imgName: 'honeyPie',
    dogName: "Blackbird's Honey Pie",
    sire: `Ocean State's Encore Performance "Calvin"`,
    dam: 'Berkshire Hills Miss Lizzy',
    alaaNum: 'ALAA-041083',
    alcaNum: 'C023-11172013-033-LB1',
    dogInfo: honeyPieInfo,
    tableData: honeyPieData,
    pawPrintLinkUrl:
      'https://www.pawprintgenetics.com/pedigrees/dogs/details/4853/',
  },
  {
    imgName: 'sadie',
    dogName: "Blackbird's Sadie",
    sire: `Ocean State's Encore Performance "Calvin"`,
    dam: 'Berkshire Hills Miss Lizzy',
    alaaNum: 'ALAA-043717',
    alcaNum: '240-05182015-001-LB2',
    dogInfo: sadieInfo,
    tableData: sadieData,
    pawPrintLinkUrl:
      'https://www.pawprintgenetics.com/pedigrees/dogs/details/4854/',
  },
];
