import React from 'react';
import { Link } from 'react-router-dom';

interface NavbarProps {
  className?: string;
}

export const Navbar = ({ className }: NavbarProps) => {
  return (
    <div className={className}>
      <nav className="flex flex-row justify-between py-5">
        <Link to="/" className="nav-link">
          Home
        </Link>
        <Link to="/about-us" className="nav-link">
          About Us
        </Link>
        <Link to="/puppy-news" className="nav-link">
          Puppy News
        </Link>
        <Link to="/our-dogs" className="nav-link">
          Our Dogs
        </Link>
        <div className="subnav">
          <button className="subnavbtn">The Breed</button>
          <div className="subnav-content">
            <Link to="/breed-history">Breed History</Link>
            <Link to="/about-the-breed">About the Breed</Link>
            <Link to="/grooming">Grooming</Link>
          </div>
        </div>
        <div className="subnav">
          <button className="subnavbtn">Past Litters</button>
          <div className="subnav-content">
            <Link to="/sadie-jack-litter">Sadie X Jack Litter</Link>
            <Link to="/lizzy-pablo-litter">Lizzy X Pablo Litter</Link>
            <Link to="/honey-pie-stewart-litter">
              Honey Pie X Stewart Litter
            </Link>
            <Link to="/lizzy-guinness-litter">Lizzy X Guinness Litter</Link>
            <Link to="/lizzy-calvin-litter">Lizzy X Calvin Litter</Link>
          </div>
        </div>
        <Link to="/alumni-gallery" className="nav-link">
          Alumni Gallery
        </Link>
        <Link to="/contact-us" className="nav-link">
          Contact Us
        </Link>
      </nav>
    </div>
  );
};
