import React from 'react';
import './styles/index.scss';
import { Routes, Route } from 'react-router';
import { Navbar } from './components/navbar';
import { HomePage } from './components/home';
import { AboutUs } from './components/about-us';
import { PuppyNews } from './components/puppy-news';
import { OurDogs } from './components/our-dogs';
import { BreedHistory } from './components/breed-history';
import { AboutTheBreed } from './components/about-the-breed';
import { Grooming } from './components/grooming';
// import { SadieJackLitter } from './components/past-litters/sadie-jack-litter';
// import { LizzyPabloLitter } from './components/past-litters/lizzy-pablo-litter';
// import { HoneyPieStewartLitter } from './components/past-litters/honey-pie-stewart-litter';
// import { LizzyGuinnessLitter } from './components/past-litters/lizzy-guinness-litter';
// import { LizzyCalvinLitter } from './components/past-litters/lizzy-calvin-litter';
import { PageUnderConstruction } from './components/page-under-construction';

function App() {
  return (
    <div className="app">
      <h1>Blackbird Labradoodles</h1>
      <Navbar className="navbar" />
      <Routes>
        <Route
          path="/contact-us"
          element={<PageUnderConstruction className="body" />}
        />
        <Route
          path="/alumni-gallery"
          element={<PageUnderConstruction className="body" />}
        />
        <Route
          path="/lizzy-calvin-litter"
          element={<PageUnderConstruction className="body" />}
        />
        <Route
          path="/lizzy-guinness-litter"
          element={<PageUnderConstruction className="body" />}
        />
        <Route
          path="/honey-pie-stewart-litter"
          element={<PageUnderConstruction className="body" />}
        />
        <Route
          path="/lizzy-pablo-litter"
          element={<PageUnderConstruction className="body" />}
        />
        <Route
          path="/sadie-jack-litter"
          element={<PageUnderConstruction className="body" />}
        />
        <Route path="/grooming" element={<Grooming className="thinBody" />} />
        <Route
          path="/about-the-breed"
          element={<AboutTheBreed className="thinBody" />}
        />
        <Route
          path="/breed-history"
          element={<BreedHistory className="thinBody" />}
        />
        <Route path="/our-dogs" element={<OurDogs className="body" />} />
        <Route path="/puppy-news" element={<PuppyNews className="body" />} />
        <Route path="/about-us" element={<AboutUs className="thinBody" />} />
        <Route path="/" element={<HomePage className="body" />} />
      </Routes>
    </div>
  );
}

export default App;
