import React from 'react';
import AboutTheBreedBanner from '../../data/images/page-header-images/AboutTheBreedBanner.png';
import { aboutTheBreedData } from '../../data/component-data/about-the-breed-data';

interface AboutTheBreedProps {
  className?: string;
}

export const AboutTheBreed = ({ className }: AboutTheBreedProps) => {
  return (
    <div className={className}>
      <img
        src={AboutTheBreedBanner}
        alt="About The Breed Banner"
        className="w-full pb-5"
      />
      <h2>About the Breed</h2>
      {aboutTheBreedData.map(breedDataObj => (
        <div className="py-5">
          <h3 className="pb-2">{breedDataObj.title}</h3>
          {breedDataObj.exampleLinkUrl ? (
            <p>
              {breedDataObj.body} The{' '}
              <a href={breedDataObj.exampleLinkUrl}>
                Australian Labradoodle Association, Inc.
              </a>{' '}
              shows good examples of the different hair types.
            </p>
          ) : (
            <p>{breedDataObj.body}</p>
          )}
          {breedDataObj.listItems ? (
            <ul className="list-disc pl-8 pt-2">
              {breedDataObj.listItems.map(listItem => (
                <li>{listItem}</li>
              ))}
            </ul>
          ) : null}
          {breedDataObj.sections ? (
            <div>
              {breedDataObj.sections.map(sectionObj => (
                <div>
                  <h4 className="pt-3 pb-3">{sectionObj.sectionHeading}</h4>
                  <p>{sectionObj.sectionBody}</p>
                </div>
              ))}
            </div>
          ) : null}
        </div>
      ))}
      <div>
        <h3>Breed Standards</h3>
        <p>
          Australian Labradoodle breed standards can be found for each of the
          Labradoodle organizations listed below on each of their websites:
        </p>
        <ul className="list-disc pl-8 pt-2">
          <li>
            <a href="http://www.laa.org.au/index.php/about-labs/standards">
              The Australian Labradoodle Association
            </a>
          </li>
          <li>
            <a href="http://alaa-labradoodles.com/breed-standard.html">
              The Australian Labradoodle Association of America
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};
