import React from 'react';
import BreedHistoryBanner from '../../data/images/page-header-images/BreedHistoryBanner.jpg';
interface BreedHistoryProps {
  className?: string;
}

export const BreedHistory = ({ className }: BreedHistoryProps) => {
  return (
    <div className={className}>
      <img
        src={BreedHistoryBanner}
        alt="Breed History Banner"
        className="w-full pb-5"
      />
      <h2 className="pb-8">Breed History</h2>
      <p>
        In the late 1980s Wally Conron, through the Royal Guide Dog Assn. of
        Victoria Australia, set out to find an allergy and asthma friendly guide
        dog at the request of a vision-impaired woman from Hawaii. After
        rejecting countless poodles with various problems, Wally crossed a
        standard poodle with one of their best producing Labradors. Out of those
        three pups that were born, one was allergy friendly. He aired a story on
        a Melbourne television channel where he coined the word Labradoodle to
        describe this new Guide Dog. Soon the new breed of Guide Dogs was in
        great demand.
        <br />
        <br />
        When Wally Conron ended his program, two breeding and research centers,
        Rutland Manor and Tegan Park, continued on with the goal of developing
        consistent non-shedding, allergy and asthma friendly dogs with the
        temperament of a service dog. They selectively bred to improve
        conformation, coat type, and temperament. In the 1990s other pure breed
        dogs were infused into the developing new breed. Each infusion was
        selected for specific reasons and at specific times. The other pure
        breeds used were: the Irish Water Spaniel, the English Cocker Spaniel
        and the American Cocker Spaniel. Some lines have reported infusions of
        Curly Coated Retriever. Today, the Australian Labradoodle would be those
        dogs with pedigrees to include the initial cross between the Poodle and
        Labrador, with the spaniel infusion included.
      </p>
    </div>
  );
};
