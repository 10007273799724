import React from 'react';
import { DogData } from '../../data/component-data/our-dogs-data';
import { DogInfoTable } from './dog-info-table';
import LizzyImage from '../../data/images/our-dogs-images/our_dogs_lizzy.jpeg';
import HoneyPieImage from '../../data/images/our-dogs-images/our_dogs_hp.jpeg';
import SadieImage from '../../data/images/our-dogs-images/our_dogs_sadie.jpeg';

interface DogDataProps {
  className?: string;
  dogData: DogData;
}

interface DogImages {
  lizzy: any;
  honeyPie: any;
  sadie: any;
}

export const DogDataSection = (props: DogDataProps) => {
  const { className, dogData } = props;

  const images: DogImages = {
    lizzy: LizzyImage,
    honeyPie: HoneyPieImage,
    sadie: SadieImage,
  };

  const whichImage = (imageObj: DogImages, imgName: string) => {
    if (imgName === 'lizzy') return imageObj.lizzy;
    return imgName === 'honeyPie' ? imageObj.honeyPie : imageObj.sadie;
  };

  const dogImage = whichImage(images, dogData.imgName);

  return (
    <div className={className}>
      <div className="w-1/6 pt-3 pr-5">
        <img src={dogImage} alt={dogData.dogName} className="aspect-[3/4]" />
      </div>
      <div className="w-5/12">
        <p>
          <b>{dogData.dogName}</b>
        </p>
        <p>
          Sire: {dogData.sire}
          <br />
          Dam: {dogData.dam}
          <br />
          ALAA Reg. No. {dogData.alaaNum}
          <br />
          ALCA Reg. No. {dogData.alcaNum}
        </p>
        <p className="pt-6">{dogData.dogInfo}</p>
      </div>
      <DogInfoTable
        className="w-5/12"
        dogTableData={dogData.tableData}
        pawPrintLinkUrl={dogData.pawPrintLinkUrl}
      />
    </div>
  );
};
