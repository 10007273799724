import React from 'react';
import { DogTableData } from '../../data/component-data/our-dogs-data';

interface DogInfoTableProps {
  className?: string;
  dogTableData: DogTableData;
  pawPrintLinkUrl?: string;
}

export const DogInfoTable = ({
  className,
  dogTableData,
  pawPrintLinkUrl,
}: DogInfoTableProps) => {
  if (!dogTableData) return null;

  return (
    <div className={className}>
      <table className="w-full">
        <tbody>
          {dogTableData.map(rowArray => (
            <tr>
              <td>{rowArray[0]}</td>
              <td>{rowArray[1]}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {pawPrintLinkUrl ? (
        <p>
          <a className="text-blue-800" href={pawPrintLinkUrl}>
            Paw Print Genetics
          </a>{' '}
          Essential Panel - All Clear
        </p>
      ) : null}
    </div>
  );
};
