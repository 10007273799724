import React from 'react';
import WelcomeImage from '../../data/images/home-page-images/welcome-image.jpeg';

interface WelcomeCardProps {
  className?: string;
}

export const WelcomeCard = ({ className }: WelcomeCardProps) => {
  return (
    <div className={className}>
      <h3 className="py-4">Welcome</h3>
      <img
        src={WelcomeImage}
        alt="playing fetch out back"
        className="pr-8 pb-3"
      />
      <p className="pr-3 pt-2">
        Welcome to Blackbird Labradoodles, breeders of the true Australian
        Labradoodle!
        <br />
        <br />
        We are not actively breeding so have no available puppies and are taking
        no reservations.
        <br />
        <br />
        Feel free to browse our website to learn more about the breed we love
        and our past litters.
      </p>
    </div>
  );
};
