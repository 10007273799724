import React from 'react';
import OurAccreditationImage from '../../data/images/home-page-images/our-accreditation-image.jpeg';

interface OurAccreditationsCardProps {
  className?: string;
}

export const OurAccreditationsCard = ({
  className,
}: OurAccreditationsCardProps) => {
  return (
    <div className={className}>
      <h3 className="py-4">Our Accreditations</h3>
      <img
        src={OurAccreditationImage}
        alt="lizzy relaxing in the shade"
        className="pr-8 pb-3"
      />
      <p className="pr-3 pt-2">
        All of our breeding dogs are certified Australian Labradoodles and are
        registered with the Australian Labradoodle Association of America. Our
        dogs exceed the minimum health testing requirements as set forth by the{' '}
        <a href="https://alaa-labradoodles.com/for-breeders/paw-reward-program/">
          ALAA
        </a>{' '}
        and are tested to the gold paw level.
        <br />
        <br />
        We are{' '}
        <a href="https://www.avidog.com/avidog-associate-breeder/">
          Avidog Associate Breeders
        </a>{' '}
        and continue to stay abreast of the latest and best breeding practices
        with their platinum college membership. All of our puppies have been
        raised the Avidog way. See links below.
        {/* Insert Avidog and Paw Print link images here */}
      </p>
    </div>
  );
};
