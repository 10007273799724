import React from 'react';
import PuppyNewsBanner from '../../data/images/page-header-images/PuppyNewsBanner.png';

interface PuppyNewsProps {
  className?: string;
}

export const PuppyNews = ({ className }: PuppyNewsProps) => {
  return (
    <div className={className}>
      <img
        src={PuppyNewsBanner}
        alt="Puppy News Banner"
        className="w-full pb-5"
      />
      <h2 className="pb-5">We do not have puppies available at this time.</h2>
      <p className="text-center pb-5">
        Thank you for your interest in our puppies!
      </p>
      <p className="text-center pb-5">
        For pictures of past litters, please select{' '}
        <b>
          <em>"Past Litters"</em>
        </b>{' '}
        from the menu.
      </p>
    </div>
  );
};
