import React from 'react';

interface PageUnderConstructionProps {
  className?: string;
}

export const PageUnderConstruction = ({
  className,
}: PageUnderConstructionProps) => {
  return (
    <div className={className}>
      <h2>We're Sorry!</h2>
      <h4 className="text-center mt-8">
        Parts of our site are temporarily under reconstruction. Please check
        back soon!
      </h4>
    </div>
  );
};
