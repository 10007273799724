import React from 'react';
import AboutUsBanner from '../../data/images/page-header-images/AboutBanner.png';
interface AboutUsProps {
  className?: string;
}

export const AboutUs = ({ className }: AboutUsProps) => {
  return (
    <div className={className}>
      <img src={AboutUsBanner} alt="About Us Banner" className="w-full pb-5" />
      <h3 className="pb-5">About Us</h3>
      <p>
        We are a small in-home breeder of Australian Labradoodles located in
        central Delaware. In the Mid-Atlantic region, we are convenient to
        Maryland, Pennsylvania, New Jersey, and a short drive from Virginia,
        D.C. or New York City.
        <br />
        <br />
        We love animals and lead an active outdoor life. We learned about
        Australian Labradoodles when we started our search for another family
        dog. Since we already shared our home with three cats, we liked the idea
        of a dog with a low to no shedding coat. Known for its intelligence and
        wonderful temperament, the Australian Labradoodle stood out! At about
        the same time, we were looking for something new and rewarding to do as
        we transitioned into retirement. Guess you could say we were bit by the
        Labradoodle love bug and Blackbird Labradoodles was born.
        <br />
        <br />
        After an extensive search for an excellent foundation dog, we found
        Lizzy. Her pedigree goes back to the bloodlines of the founders in
        Australia. We thank Sunny at Berkshire Hills for Lizzy and for sharing
        her knowledge and love of Labradoodles with us.
        <br />
        <br />
        We are committed to producing healthy, beautiful Labradoodles with the
        temperaments that they are known for. We are dedicated to preserving the
        Australian Labradoodle for its original purpose, an allergy friendly,
        non shedding, therapy and companion dog. We have pledged to always use
        sound breeding practices, and the highest standard of ethics. We are
        excited to be a part of this developing breed.
        <br />
        <br />
        Thank you for visiting our site and taking the time to learn a little
        about us and our labradoodles!
        <br />
        <br />
        Louanne and Bruce
      </p>
    </div>
  );
};
