import React from 'react';
import GroomingBanner from '../../data/images/page-header-images/GroomingBanner.jpg';

interface GroomingProps {
  className?: string;
}

export const Grooming = ({ className }: GroomingProps) => {
  return (
    <div className={className}>
      <img src={GroomingBanner} alt="Grooming Banner" className="w-full pb-5" />
      <h2>Grooming</h2>
      <p className="py-5">
        Australian Labradoodles need regular brushing and grooming. The puppy
        coat is easy to maintain, but regular brushing will get your puppy
        accustomed to grooming. Between around 8-14 months, your puppy's adult
        coat will begin to come in. You will need to brush daily during this
        time as the puppy coat will need to be brushed out so that it does not
        mat with the adult coat. When the puppy to adult coat change is done,
        the adult coat should be brushed at least a couple times per week.
        Length of the labradoodle coat is a personal preference.
      </p>
    </div>
  );
};
