import React from 'react';
import LocationAndContactImage from '../../data/images/home-page-images/location-contact-image.jpeg';

interface LocationAndContactCardProps {
  className?: string;
}

export const LocationAndContactCard = ({
  className,
}: LocationAndContactCardProps) => {
  return (
    <div className={className}>
      <h3 className="py-4">Location & Contact</h3>
      <img
        src={LocationAndContactImage}
        alt="a doodle staredown"
        className="pr-8 pb-3"
      />
      <p className="pr-3 pt-2">
        We are located in the state of Delaware, which has no sales tax. We are
        a short drive from PA, NJ, MD, DC/VA & NYC.
        <br />
        <a href="https://www.blackbirdlabradoodles.com/contact-us">
          {' '}
          {/* Clean this link up */}
          Click here
        </a>{' '}
        for our contact information
      </p>
    </div>
  );
};
