import React from 'react';
import OurDogsBanner from '../../data/images/page-header-images/OurDogsBanner.png';
import { DogDataSection } from './dog-data';
import { ourDogsData } from '../../data/component-data/our-dogs-data';

interface OurDogsProps {
  className?: string;
}

export const OurDogs = ({ className }: OurDogsProps) => {
  return (
    <div className={className}>
      <img src={OurDogsBanner} alt="Our Dogs Banner" className="w-full pb-5" />
      <h2 className="text-left py-5">Our Dogs</h2>
      {ourDogsData.map(dogData => (
        <DogDataSection
          key={dogData.dogName}
          className="flex flex-row space-evenly pb-8"
          dogData={dogData}
        />
      ))}
    </div>
  );
};
