export interface BreedDataSection {
  sectionHeading: string;
  sectionBody: string;
}

export interface AboutTheBreedData {
  title: string;
  body?: string;
  listItems?: string[];
  exampleLinkUrl?: string;
  sections?: BreedDataSection[];
}

const coatsSections: BreedDataSection[] = [
  {
    sectionHeading: 'Fleece',
    sectionBody:
      'The Fleece textured coat is light and silky, a soft texture as in the Angora goat. The two variations are wavy fleece and curly fleece.  The fleece coat can be from loosely waved giving an almost straight appearance to deeply waved.',
  },
  {
    sectionHeading: 'Wool',
    sectionBody:
      'The Wool coat is like a lamb’s wool in texture.  It is mildly to very curly and should "hang" in loose hollow spirals, which parts easily to the skin.  It should not appear thick and dense or tightly curled.',
  },
  {
    sectionHeading: 'Hair',
    sectionBody:
      'The hair coat has odor and sheds in varying degrees.  Usually seen in early generation dogs.',
  },
];

const colorsSections: BreedDataSection[] = [
  {
    sectionHeading: 'Chalk',
    sectionBody:
      'Chalk is a white color, but when compared to white is rather a chalky-white in color.  Nose pigment is black or rose.  Chalk dogs with brown/rose noses are sometimes referred to as Caramel Ice.',
  },
  {
    sectionHeading: 'Cream',
    sectionBody:
      'Cream should be a creamy coloring sometimes with apricot/gold tinting.  Nose pigment to be black or rose.  Cream dogs with brown/rose noses are sometimes referred to as Caramel Cream.',
  },
  {
    sectionHeading: 'Gold',
    sectionBody:
      'Gold is also referred to as “apricot” and should be the color of the inside of a ripe apricot to varying shades of rich gold in color.  A true gold should not have a lighter root than the outer coat and should have an even coloration over the entire body.  This color may fade as the dog grows older.  Nose pigment to be black.',
  },
  {
    sectionHeading: 'Caramel',
    sectionBody:
      "Caramel ranges from a rich gold through to a deep red, but the preferred color is the same color as its namesake 'caramel'.  Nose pigment should be rose.",
  },
  {
    sectionHeading: 'Red',
    sectionBody:
      'Red should be a solid even rich red in color.  A true red should not be lighter at the root than the outer coat.  Reds can fade as the dog grows older. Nose pigment to be black.  This is a rare color group.',
  },
  {
    sectionHeading: 'Black',
    sectionBody:
      'Black should be a solid with no sprinkling of any other color through the coat.  Nose pigment to be black.',
  },
  {
    sectionHeading: 'Silver',
    sectionBody:
      'Silver can range in shades from very light pewter in color to a dark charcoal. Silvers are born black with the coat color developing over time (1-3 yrs).  Nose pigment is black.',
  },
  {
    sectionHeading: 'Blue',
    sectionBody:
      'Blue should be a dark to medium smoky blue in color. Blues are born black but will have a blue/grey skin pigment. The blue coat color will develop over time (1-3yrs) but as a developed adult should have an even coat color. Nose pigment to be blue/grey (matching the skin pigmentation). Rare color group.',
  },
  {
    sectionHeading: 'Chocolate',
    sectionBody:
      'Chocolate should be dark and rich in color. True chocolates are born almost black in color and maintain the rich dark color throughout their lifetime. Nose pigment to be rose in color (matching the coat color). Rare color group.',
  },
  {
    sectionHeading: 'Café',
    sectionBody:
      'Café ranges from a milk chocolate to silver-beige in color and will develop over time (1-3yrs).  Nose pigment to be rose in color (matching the coat color).',
  },
  {
    sectionHeading: 'Lavender',
    sectionBody:
      'Lavender is a smoky lavender chocolate color giving an almost pink to lilac appearance. Lavender dogs are born chocolate and will develop over time (1-3yrs).  Nose pigment to be rose in color (matching the coat color).  Rare color group.',
  },
  {
    sectionHeading: 'Parchment',
    sectionBody:
      'Parchment is a creamy beige chocolate color reminiscent of a cup of coffee with a generous addition of milk. Parchment dogs are born milk chocolate and will develop over time (1-3yrs).  From a distance adult dogs can be mistaken for a dark or smoky cream. Nose pigment to be rose in color. (Rare color group).',
  },
  {
    sectionHeading: 'Parti',
    sectionBody:
      'Partis are at least fifty percent white, with spots or patches of any other above solid color. The head can be of a solid color but white muzzle, blaze, or white muzzle/blaze combination (preferably symmetrical) are equally acceptable. Full or partial saddles are acceptable, as long as they do not exceed the color proportion, but are not preferred. Ticking in the white of the coat is acceptable but not preferred. Nose pigment to match the solid color requirements as listed above.',
  },
  {
    sectionHeading: 'Phantom',
    sectionBody:
      'Phantoms have a solid base color with sharply defined markings of a second color appearing above each eye, on the sides of the muzzle, on the throat and fore chest, or in a chin and fore chest bow tie pattern as well as on all four legs and feet, and below the tail. A phantom without clearly defined face markings or one that presents with its whole face colored in the second color is acceptable, as long as it maintains all the other specified body markings. Any combination of acceptable colors is allowed. Nose pigment should follow requirements listed above based on the solid base color. Less than fifty percent white, with the remaining percent any other acceptable solid color.',
  },
  {
    sectionHeading: 'Abstract',
    sectionBody:
      'Abstracts are less than fifty percent white, with the remaining percent any other acceptable solid color.',
  },
  {
    sectionHeading: 'Sable',
    sectionBody:
      'Sables have coats represented by black-tipped hairs on a background of any solid color, with no particular pattern/location designated for such hairs.',
  },
  {
    sectionHeading: 'Brindle',
    sectionBody:
      'Brindles should have an even and equal distribution of the composite colors with layering of black hairs in regions of lighter color (usually, chalk/cream/gold/red, cafe/lavender/parchment, or silver) producing a tiger-striped pattern.',
  },
  {
    sectionHeading: 'Multi Pattern',
    sectionBody:
      'A dog that clearly exhibits more than one of the acceptable color patterns, such as; a Parti with full or incomplete phantom markings (facial markings with or without presentation of the diamond under the tail), or a Phantom with additional abstract markings, etc.',
  },
];

export const aboutTheBreedData: AboutTheBreedData[] = [
  {
    title: 'Temperament',
    body: 'Australian Labradoodles are highly intelligent, sociable, comical, happy, energetic and intuitive.  They pick up basic commands very quickly and are often the star pupil in puppy training class.  They thrive on being mentally and physically engaged and should be given daily exercise.  Early, consistent training is recommended to manage their natural enthusiasm.  People oriented, they are happiest when they are with their family.  They are alert barkers, but not aggressive.  Their life expectancy is 12 to 15 years.',
  },
  {
    title: 'Sizes',
    body: 'At this stage in the breed’s development, the Australian Labradoodle comes in three size ranges.  Inter-size breeding is acceptable and expected.  Measurement is from the floor to the top of shoulders (not the top of the head).',
    listItems: [
      'Standard – Between 21” and 24” in height, but not more than 25”. The weight range tends to be 50 to 65 pounds.',
      'Mediums – 17" to 20" in height and weight range tends to be 30 to 40 pounds.',
      'Miniatures – 14"to 16" in height and a weight range tends to be 16 to 25 pounds.',
    ],
  },
  {
    title: 'Coats',
    body: 'There are three coat types, fleece, wool and hair.  The fleece and the wool coat are considered allergy friendly.  These are low to non-shedding coats and would be seen on the multigenerational labradoodles.  The last coat type, seen primarily in the early generation labradoodles, is the hair coat.  It varies from short wiry to long combination.',
    exampleLinkUrl: 'http://www.laa.org.au/index.php/about-labs/coats',
    sections: coatsSections,
  },
  {
    title: 'Colors',
    sections: colorsSections,
  },
];
